#user {
  color: black;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#f00;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swal2-popup {
  z-index: 999999999 !important;
}

.swal2-container {
  z-index: 999999999 !important;
}

* {
  outline: 0;
}

.create-credit-input {
  width: 70%;
}

.create-credit-input-row {
  margin-bottom: 1rem;
  text-align: center;
  height: 4rem;
  vertical-align: bottom;
  display: inline-block;

  & > * {
    display: inline-block;
    vertical-align: bottom;

    //align-self: flex-end;

  }
}

.block-massive-liq-checkbox {
  text-align: left;
  vertical-align: bottom;
  display: inline-block;
  padding-top: 6.2% ;
  padding-left: 12% ;
}
.alert {
  position: fixed !important;
  z-index: 1000;
  right: 0;
  margin-right: 20px;
}

.tooltip-main {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-weight: 700;
  background: #f3f3f3;
  border: 1px solid #737373;
  color: #737373;
  margin: 4px 121px 0 5px;
  float: right;
  text-align: left !important;
  opacity: 1 !important;
}

.tooltip > .tooltip-inner {
  opacity: 1 !important;
}

.tooltip-qm {
  float: left;
  margin: -2px 0px 3px 4px;
  font-size: 12px;
}

.tooltip > .tooltip-inner {
  //background: white;
  color: black;
  border: 1px solid #737373;
  opacity: 100%;
}

.tooltip.show {
  opacity: 1 !important;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f00;
  /* Red */
}

.selectorDownload {
  width: 20rem !important;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.selectorDownloadGlobal {
  width: 20rem !important;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.selectorLiq {
  width: 20rem !important;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.body {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #f00;
}

/* Nav bar */
.nav-bar {
  z-index: 5000;
  display: block;
  background-color: #f00 !important;
  color: white !important;
}

.nav-bar .title {
  position: relative;
  color:#f00;
}

.coverLogin {
  color: black;
  font-size: 34px;
  width: 100%;
  height: auto;
  text-align: left;
  top: 37%;
  display: inline-block;
  position: relative;
}

/* navbar when the content is scrolled*/

.scrolled {
  box-shadow: 2px -3px 11px 0px;
  background-color: red;
  color: var(--text-color);
}

.dropdown-toggle{
  color:white !important;
}

.sidebar-element {
  justify-content: left !important;
  padding: 8px 16px 8px 16px !important;
}

.navbar-element {
  justify-content: right !important;
  padding: 0px 0px 0px 0px !important;//top right bottom left
}
.navbar-element-2 {
  justify-content: right !important;
  padding: 0px 0px 4px 0px !important;//top right bottom left
}

@media only screen and (max-width: 600px) {
  .nav-bar .nav-buttons {
    padding: 0px 0 0px 0;
    width: 100%;
    position: fixed;
    top: 60px;
    flex-direction: column;
    color: white;
    background: red;
    height: 200px;
    display: none;
    border-radius: 0 0 6px 6px;
  }
  .nav-bar .nav-buttons .nav-button {
    display: block;
    margin: auto;
  }

  .nav-bar .menu-button {
    display: initial;
    position: absolute;
    border-radius: 5px;
  }
}

/* content */
.content {
  overflow: auto;
  flex-grow: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.table-hover > tbody > tr:hover {
  //background-color: #ffffff !important; /* Assuming you want the hover color to be white */
}

.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
  background-color: white !important;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: white !important;
}
/* elements */

.card {
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100% !important;
}

.acordeon {
  width: 30rem;
}

.acordeonDiv{
  padding-left: 26%;
}
#proyTable {
  display: none;
}

.auxDetail {
  display: inherit !important;
  position: relative;
  color: rgb(255, 255, 255) !important;
  background-color: #18303a !important;
  border-color: #f2f2f2 !important;
  padding: 0.2rem !important;
  height: 1rem !important;
  font-size: 8pt !important;
  line-height: 7.8px !important;
  width: 10px;
}

.cardSquare {
  width: 30%;
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.cardDashboard {
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 16px;
}

.center {
  position: absolute;
  height: auto;
  top: 10%;
  display: inline-block;
  width: 100%;
  position: absolute;
}

.loginHead {
  width: 100%;
  text-align: center;
}

.headDashboard {
  margin-top: 10px;
  font-size: 20px;
}

.formTitle {
  font-size: 17px;
  font-weight: 400;
}

.formHeader {
  font-size: 19px;
  font-weight: 400;
}

.formTitleDetail {
  font-size: 17px;
  font-weight: 400;
  text-align: left;
}

.detailCuota {
  text-align: left;
}

.border {
  padding: 15px 0px 0px 0px;
}

.selector {
  margin: 12px 0px 12px 0px;
  padding: 6px 8px 6px 8px;
}

p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

@media only screen and (min-width: 600px) {
  .card {
    margin: 0 auto;
    max-width: 1000px;
    margin-bottom: 2rem;
  }
  .cardSquare {
    margin: 0 auto;
    max-width: 1000px;
  }
}
@media only screen and (max-width: 600px) {
  .card {
    top: 0px;
  }
  .cardSquare {
    top: 0px;
    width: 100%;
    border: 1px solid rgb(255, 255, 255);
  }
  .center {
    position: absolute;
    height: auto;
    top: 0%;
    display: inline-block;
    width: 100%;
    position: absolute;
  }
  form [type="auxSubmitMarginTBL"] {
    margin-top: 24px;
  }
}

#table {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#table td,
#table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffffff;
  color: black;
  font-weight: 500;
}

/*FORM*/

.loginWrapper {
  display: block;
  position: relative;
  width: 350px;
  text-align: center;
  margin: auto;
  right: 0;
  left: 0;
  margin-top: 60px;
  margin-bottom: 60px;
  z-index: 1000;
  transition: box-shadow 1s;
}

.logginFormFooter {
  text-align: center;
  color: #777;
  width: 100%;
  font-size: 12px;
  position: fixed;
  bottom: 10px;
}

.logginFormFooter a {
  color: #777;
  font-weight: 600;
}
.logginFormFooter a:hover {
  color: #aaa;
}

* {
  box-sizing: border-box;
  //padding: 0;
  //margin: 0;
}

nav {
  width: 100%;
  text-align: center;
}

.tabs > li:hover:before {
  -webkit-transform: translateY(70%);
  transform: translateY(70%);
}
.tabs > li.active {
  color: #444;
}
.tabs > li.active:before {
  transition-duration: 0.5s;
  background-color: #fff;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.tab__content > li {
  width: 100%;
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  display: none;
  list-style: none;
}
.tab__content > li .content__wrapper {
  text-align: center;
  border-radius: 8px;
  padding-top: 24px;
  background-color: #fff;
}

form input {
  margin: 12px 0px;
  border-radius: 4px;
}
.rightBtn {
  width: auto;
  text-align: right;
}

.selectorSegVida {
  margin: 12px 0px;
  border-radius: 4px;
}

.selectorSegTodoRiesgo {
  margin: 12px 0px;
  border-radius: 4px;
}

.input-group-append {
  padding-top: 0.75rem !important;
}

.selectorEstado {
  margin: 12px 0px;
  border-radius: 4px;
}

#asd {
  width: 100%;
}









/*
form [type="submit"] {
  background: #18303a;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
}
*/






.cover {
  color: black;
  font-size: 50px;
  width: 100%;
  height: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 37%;
  display: inline-block;
  position: absolute;
}

#entTitle {
  font-size: 30px;
  font-weight: 400;
}
.entBorder {
  padding: 10px;
}

#datePicker {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  /*background-color: #216ba5;*/
  background-color: #18303a;
  font: inherit;
  color: #fff;
  margin: 12px 0px 18px 0px;
}

.separator {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #fff;
  margin-top: 10px;
  margin-bottom: 8px;
  position: relative;
}

#optionPesosLiq {
  display: none;
}

#optionPesosProy {
  display: none;
}

.sidebar {
  height: fit-content !important;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  //min-height: 100vh !important;
  //z-index: 100;
  //padding: 48px 0 0;
  //box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  //padding: 0px !important;
  //margin: 0px !important;
}
#sidebar-wrapper{
  //min-height: 100vh !important;
  //width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  //padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}